import React from "react"
import { Row, Col, Button, Form } from "react-bootstrap"
import { useFormik } from "formik"
import InputMask from "react-input-mask"

const FormComponent = () => {
  const validate = values => {
    const errors = {}

    if (!values.name) {
      errors.name = "Campo obrigatório"
    }

    if (!values.company) {
      errors.company = "Campo obrigatório"
    }

    if (!values.phone) {
      errors.phone = "Campo obrigatório"
    }

    if (!values.message) {
      errors.message = "Campo obrigatório"
    }

    if (!values.email) {
      errors.email = "Campo obrigatório"
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Digite um email válido"
    }

    return errors
  }

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      company: "",
      email: "",
      message: "",
    },
    validate,
    onSubmit: values => {
      fetch(`${process.env.API_URL || "http://localhost:1337"}/contactforms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(values),
      })
        .then(response => {
          if (!response.ok) throw response
        })
        .then(result => alert("Mensagem enviada com sucesso!"))
        .catch(error => {
          alert("Houve um erro, tente novamente ou nos chame no Whatsapp!")
          formik.setSubmitting(false)
        })
    },
  })

  return (
    <Row className="contact">
      <Col className="form-holder">
        <Form onSubmit={formik.handleSubmit} className="contactform">
          <Form.Row>
            <Col className="mr-1 mr-md-3">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Nome"
                  name="name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div>
                    <small>{formik.errors.name}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col className="ml-1 ml-md-3">
              <Form.Group controlId="phone">
                <InputMask
                  type="text"
                  placeholder="Telefone"
                  name="phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.phone}
                  mask="(99) 9 9999-9999"
                  maskChar=" "
                  className="form-control"
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <div>
                    <small>{formik.errors.phone}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col className="mr-1 mr-md-3">
              <Form.Group>
                <Form.Control
                  type="text"
                  placeholder="Empresa"
                  name="company"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.company}
                />
                {formik.touched.company && formik.errors.company ? (
                  <div>
                    <small>{formik.errors.company}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
            <Col className="ml-1 ml-md-3">
              <Form.Group>
                <Form.Control
                  type="email"
                  placeholder="Email"
                  name="email"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                />
                {formik.touched.email && formik.errors.email ? (
                  <div>
                    <small>{formik.errors.email}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group>
                <Form.Control
                  type="text"
                  as="textarea"
                  placeholder="Mensagem"
                  name="message"
                  rows="2"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                />
                {formik.touched.message && formik.errors.message ? (
                  <div>
                    <small>{formik.errors.message}</small>
                  </div>
                ) : null}
              </Form.Group>
            </Col>
          </Form.Row>
          <Form.Row>
            <Col>
              <Form.Group className="text-center mt-2">
                <Button
                  variant="secondary"
                  type="submit"
                  size="md"
                  disabled={
                    !(formik.isValid && formik.dirty) || formik.isSubmitting
                  }
                >
                  Enviar
                </Button>
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
      </Col>
    </Row>
  )
}

export default FormComponent
