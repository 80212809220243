import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { BsEnvelope } from "react-icons/bs"
import { FiPhoneCall, FiSmartphone } from "react-icons/fi"
import Form from "./common/form"
import "../assets/css/contact.css"

const ContactComponent = () => {
  return (
    <div className="gradient-bg">
      <Container className="padding-top padding-bottom">
        <Row className="mb-4">
          <Col>
            <div className="title">
              <h3 className="gradient-text">Contato</h3>
            </div>
            <p>
              Deixe sua mensagem e entraremos em contato o mais breve possível
            </p>
          </Col>
        </Row>
        <Form />
        <Row className="justify-content-center contact-info text-center half-padding-top">
          <Col md={3} className="mt-3 mt-md-0">
            <BsEnvelope />{" "}
            <a
              href="mailto:atendimento@unnit.com.br"
              target="_blank"
              rel="noreferrer"
            >
              atendimento@unnit.com.br
            </a>
          </Col>
          <Col md={3} className="mt-3 mt-md-0">
            <FiSmartphone /> (53) 9.9139.8387
          </Col>
          <Col md={3} className="mt-3 mt-md-0">
            <FiPhoneCall /> (53) 3029.1500
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default ContactComponent
